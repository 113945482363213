import React from "react"
import { Link } from "gatsby"

const Layout = props => {
  const { title, children } = props
  const [toggleNav, setToggleNav] = React.useState(false)
  return (
    <div id="outer-site-wrapper">
      <div className={`site-wrapper ${toggleNav ? `site-head-open` : ``}`}>
        <header className="site-head">
          <div className="site-head-container">
            <a
              className="nav-burger"
              href={`#`}
              onClick={() => setToggleNav(!toggleNav)}
            >
              <div
                className="hamburger hamburger--collapse"
                aria-label="Menu"
                role="button"
                aria-controls="navigation"
              >
                <div className="hamburger-box">
                  <div className="hamburger-inner" />
                </div>
              </div>
            </a>
            {/* <div className="site-head-top">
              <Link className="site-head-logo" to={`/`}>
                {title}
              </Link>
            </div> */}
            <nav id="swup" className="site-head-left">
              <ul className="nav" role="menu">
                <li className="nav-home" role="menuitem">
                  <Link to={`/`} activeStyle={{opacity: 1, textDecoration: "none" }}>Portfolio</Link>
                </li>
                <li className="nav-about" role="menuitem">
                  <Link to={`/about`} partiallyActive={true} activeStyle={{opacity: 1, textDecoration: "none" }}>About</Link>
                </li>
                <li className="nav-contact" role="menuitem">
                  <Link to={`/contact`} partiallyActive={true} activeStyle={{opacity: 1, textDecoration: "none" }}>Contact</Link>
                </li>

                
                



   
              </ul>
            </nav>
            <div className="site-head-center">
              
              <Link className="site-head-logo" to={`/`}>
                {title}
              </Link>
            </div>
            <div className="site-head-right">
            {/* <ul className="nav" role="menu">



                <a
                  href="https://www.instagram.com/herschelshapiro"
                  title="Instagram"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Instagram
                </a>
                <a
                  href="https://www.tiktok.com/@herschelshapiro"
                  title="TikTok"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  TikTok
                </a>
                <a
                  href="https://www.youtube.com/c/HerschelShapiro"
                  title="YouTube"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  YouTube
                </a>
                </ul> */}

              <ul className="social-links" role="menu">
                {/* <li className="nav-about" role="menuitem">
                  <a
                    href="https://herschelshapiro.etsy.com"
                    title="Etsy Shop"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Shop
                  </a>
                </li> */}
                <li className="nav-contact" role="menuitem">
                  <Link to={`/links`} partiallyActive={true} activeStyle={{opacity: 1, textDecoration: "none" }}>
                    Links
                  </Link>
                </li>
                <li className="nav-contact" role="menuitem">
                  <Link to={`/authenticate`} partiallyActive={true} activeStyle={{opacity: 1, textDecoration: "none" }}>Authenticate</Link>
                </li>
              </ul>
              

            </div>
          </div>
        </header>
        <main id="site-main" className="site-main">
          <div id="swup" className="transition-fade">
            {children}
          </div>
        </main>
        <footer className="site-foot">

        <div className="footer-social-links">
                <a
                  href="https://www.instagram.com/herschelshapiro"
                  title="Instagram"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Instagram
                </a> | 
                <a
                  href="https://www.tiktok.com/@herschelshapiro"
                  title="TikTok"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  TikTok
                </a>  | 
                <a
                  href="https://www.youtube.com/c/HerschelShapiro"
                  title="YouTube"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  YouTube
                </a>

              </div>

        <div>This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.</div>
          &copy; {new Date().getFullYear()} <Link to={`/`}>{title}</Link>
        </footer>
      </div>
    </div>
  )
}

export default Layout
